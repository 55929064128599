<template>
	<CreateDialog :dialog="createDialog" v-on:close="closeCreateDialog">
		<template v-slot:title>
			<span>{{ pageTitle() }}</span>
		</template>
		<template v-slot:body>
			<v-sheet class="project" id="project" style="height: calc(100vh - 88px)">
				<v-row>
					<v-col md="12">
						<v-form
							ref="projectForm"
							v-model.trim="formValid"
							lazy-validation
							v-on:submit.stop.prevent="updateOrCreate"
						>
							<v-row>
								<v-col md="9">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll custom-box-top-inner-shadow"
										style="position: relative"
									>
										<v-row class="px-4">
											<v-col md="3" class="py-0">
												<label class="btx-label mt-4 required">Customer</label>
											</v-col>
											<v-col md="9" class="py-0">
												<SelectCustomerSupplier
													v-model="project.customer"
													:items="customerList"
													:updateDetail="selectedCustomer"
													type="customer"
													:rules="[vrules.required(project.customer.id, 'Customer')]"
													@blur="changeCustomerId"
													placeholder="Customer"
													:class="{
														required: !project.customer.id,
													}"
													input-id="customer-id"
												></SelectCustomerSupplier>
											</v-col>
											<v-col md="3" class="py-0">
												<label for="start_date" class="btx-label mt-4 required">Start Date</label>
											</v-col>
											<v-col md="3" class="py-0">
												<DatePicker
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													:rules="[vrules.required(project.start_date, 'Start Date')]"
													:class="{
														required: !project.start_date,
													}"
													id="start-date"
													v-model="project.start_date"
													@change="saveToLocal()"
												>
												</DatePicker>
											</v-col>
											<v-col md="3" class="py-0 text-right">
												<label for="till_date" class="btx-label mt-4">Till Date </label>
											</v-col>
											<v-col md="3" class="py-0">
												<DatePicker
													:minDate="project.start_date"
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													:rules="[vrules.required(project.end_date, 'End Date')]"
													:class="{
														required: !project.end_date,
													}"
													id="till_date"
													placeholder="Till Date"
													v-model="project.end_date"
													@change="saveToLocal()"
												>
												</DatePicker>
											</v-col>
											<v-col md="3" class="py-0">
												<label class="mt-4">Purchase Order</label>
											</v-col>
											<v-col md="9" class="py-0">
												<AutoCompleteInput
													hide-details
													multiple
													:items.sync="purchaseOrderItem"
													item-text="barcode"
													item-value="id"
													placeholder="Purchase Order"
													v-model="project.purchase_order"
													:disabled="pageLoading"
													:loading="pageLoading"
													@change="savePOToLocal()"
												></AutoCompleteInput>
											</v-col>
											<v-col md="3" class="py-0">
												<label for="site-number" class="btx-label mt-4 required">Title</label>
											</v-col>
											<v-col md="9" class="py-0">
												<TextInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="title"
													placeholder="Title"
													v-model="project.title"
													:rules="[vrules.required(project.title, 'Title')]"
													:class="{
														required: !project.title,
													}"
													@change="saveToLocal()"
												></TextInput>
											</v-col>

											<v-col md="3" class="py-0">
												<label class="mt-4">Quotations</label>
											</v-col>
											<v-col md="9" class="py-0">
												<AutoCompleteInput
													hide-details
													multiple
													:items.sync="quoteItems"
													item-text="barcode"
													item-value="id"
													placeholder="quotations"
													v-model="project.quotations"
													:disabled="pageLoading"
													:loading="pageLoading"
													@change="saveQuotationToLocal()"
												>
												</AutoCompleteInput>
											</v-col>

											<v-col md="3" class="py-0">
												<label class="mt-4">References No</label>
											</v-col>
											<v-col md="9" class="py-0">
												<TextInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="references-no"
													placeholder="References No"
													v-model="project.references_no"
													@change="saveToLocal()"
												></TextInput>
											</v-col>
											<v-col md="3" class="py-0">
												<label :for="`team_members`" class="btx-label mt-4">Team Member</label>
											</v-col>
											<v-col md="9" class="py-0">
												<TeamMembers
													hide-details
													v-model="project.team_members"
													:items="teamMembersList"
													@change="saveToLocal()"
													:disabled="pageLoading"
													:loading="pageLoading"
													id="team_members"
													placeholder="Team Members"
													@remove="remove"
													multiple
												/>
											</v-col>
											<v-col md="3" class="py-0">
												<label for="description" class="btx-label mt-4">Description</label>
											</v-col>
											<v-col md="9" class="py-0">
												<TextAreaInput
													hide-details
													placeholder="Description"
													v-model="project.description"
													:disabled="pageLoading"
													id="description"
													:loading="pageLoading"
													@change="saveToLocal()"
												></TextAreaInput>
											</v-col>

											<v-col md="3" class="py-0">
												<label for="project_value" class="btx-label mt-4">Project Value</label>
											</v-col>
											<v-col md="3" class="py-0">
												<PriceInput
													type="number"
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="project_value"
													placeholder="Project Value"
													v-model="project.value"
													@change="saveToLocal()"
												></PriceInput>
											</v-col>
											<v-col md="3" class="py-0 text-right">
												<label for="project_budget" class="btx-label mt-4">Project Budget</label>
											</v-col>
											<v-col md="3" class="py-0">
												<PriceInput
													hide-details
													type="number"
													:disabled="pageLoading"
													:loading="pageLoading"
													id="project_budget"
													placeholder="Project Budget"
													v-model.number="project.budget"
													@change="saveToLocal()"
												></PriceInput>
											</v-col>
											<v-col md="3" class="py-0">
												<label for="attachment" class="btx-label mt-4">Documents/Attachment</label>
											</v-col>
											<v-col md="9" class="py-0">
												<FileUpload v-model="project.attachment"></FileUpload>
											</v-col>
											<v-col md="3" class="py-0">
												<label for="specification" class="btx-label mt-4">Specification</label>
											</v-col>
											<v-col md="9" class="py-0">
												<TextInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="specification"
													placeholder="Specification"
													v-model="project.specification"
													@change="saveToLocal()"
												></TextInput>
											</v-col>
											<v-col md="12" class="my-4">
												<p class="middle-header-background mb-2 px-4">
													Banker guarantee<br />
													<span>
														<v-icon small>mdi-progress-question</v-icon> Specify the Attachments & Other details
														for your Project</span
													>
												</p>
											</v-col>
											<v-col md="3" class="my-auto py-0">
												<label for="group" class="btx-label mt-2">Bank Name</label>
											</v-col>
											<v-col md="9" class="py-0">
												<AutoCompleteInput
													hide-details
													:items="bankList"
													:disabled="pageLoading"
													:loading="pageLoading"
													id="group"
													placeholder="Bank Name"
													item-text="name"
													item-value="id"
													v-model="project.bankers_guarantee.bank"
													append-outer-icon="mdi-cog"
													v-on:click:append-outer="manageBankDialog = true"
													@change="saveToLocal()"
												></AutoCompleteInput>
											</v-col>
											<v-col md="3" class="py-0">
												<label for="amount" class="btx-label mt-4">Amount</label>
											</v-col>
											<v-col md="9" class="py-0">
												<PriceInput
													hide-details
													type="number"
													:disabled="pageLoading"
													:loading="pageLoading"
													id="amount"
													placeholder="Amount"
													v-model.number="project.bankers_guarantee.amount"
													@change="saveToLocal()"
												/>
											</v-col>
											<v-col md="3" class="py-0">
												<label for="remark" class="btx-label mt-4">Remark</label>
											</v-col>
											<v-col md="9" class="py-0">
												<TextAreaInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="remark"
													placeholder="Remark"
													v-model="project.bankers_guarantee.remark"
													@change="saveToLocal()"
												>
												</TextAreaInput>
											</v-col>
											<v-col md="3" class="py-0">
												<label for="attachment" class="btx-label mt-4">Documents/Attachment</label>
											</v-col>
											<v-col md="9" class="py-0">
												<FileUpload v-model="project.bankers_guarantee.attachment"></FileUpload>
											</v-col>
										</v-row>
									</perfect-scrollbar>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</v-sheet>
			<ManageBank
				:dialog="manageBankDialog"
				:category="bankList"
				v-on:close-dialog="manageBankDialog = false"
				v-on:success="getCategories"
			></ManageBank>
		</template>
		<template v-slot:action>
			<v-btn class="white--text" depressed color="blue darken-4" @click="updateOrCreate" tile>
				Save
			</v-btn>
		</template>
	</CreateDialog>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { GetContactProperty } from "@/core/lib/contact.lib";
import { UploadFiles } from "@/core/lib/upload.lib";
import {
	UpdateProject,
	CreateProject,
	GetProject,
	getQuotationAndPurchaseOrder,
} from "@/core/lib/project.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TeamMembers from "@/view/components/TeamMembers";
import { map } from "lodash";
import TextInput from "@/view/components/TextInput";
import PriceInput from "@/view/components/PriceInput";
import FileUpload from "@/view/components/FileUpload";
import CreateDialog from "@/view/components/CreateDialog";
import { getData, saveData, deleteData } from "@/core/services/local.service";
import SelectCustomerSupplier from "@/view/components/SelectCustomerSupplier";
// import Dialog from "@/view/components/Dialog";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import ManageBank from "@/view/components/ManageBank";
export default {
	name: "project-create",
	title: "Create Project",
	data() {
		return {
			uuid: null,
			barcode: null,
			formValid: true,
			disabled: false,
			teamMembersList: [],
			pageLoading: false,
			attachment: [],
			bankList: [],
			propertyList: [],
			manageBankDialog: false,
			hideMoreChip: false,
			customerList: [],
			customer_billing_address: null,
			customer_contact_person: null,
			memberList: [],
			selectedCustomer: {
				id: null,
				contact_person_id: null,
				property_id: null,
			},
			project: {
				id: null,
				uuid: null,
				contact: null,
				title: null,
				// property: null,
				attachment: [],
				customer: {
					id: null,
					contact_person_id: null,
					property_id: null,
				},
				remark: null,
				start_date: null,
				end_date: null,
				members: [],
				description: null,
				value: null,
				purchase_order: [],
				quotations: [],
				references_no: null,
				amount: null,
				team_members: [],
				budget: null,
				specification: null,
				bankers_guarantee: {
					bank: null,
					amount: null,
					remark: null,
					attachment: [],
				},
			},
			createDialog: true,
			purchaseOrderItem: [],
			quoteItems: [],
			customerItems: [],
		};
	},
	components: {
		TextAreaInput,
		DatePicker,
		AutoCompleteInput,
		TextInput,
		ManageBank,
		CreateDialog,
		FileUpload,
		SelectCustomerSupplier,
		TeamMembers,
		PriceInput,
		// Dialog,
	},
	methods: {
		limitAttachment(output) {
			if (this.pageLoading) {
				return false;
			}

			this.attachment = output.slice(0, 3);

			if (this.attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < this.attachment.length; i++) {
				request.append(`files[${i}]`, this.attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.project.attachment = map(response, "path");
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCategories(param) {
			const isCategoryExist = param.some((ele) => ele.id === this.project.bankers_guarantee.bank);
			if (!isCategoryExist) {
				this.project.bankers_guarantee.bank = null;
			}
			this.bankList = param;
		},
		getCustomerProperty(customerUuid) {
			GetContactProperty(customerUuid)
				.then((data) => {
					this.propertyList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.dataLoading = false;
				});
		},

		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.projectForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.projectForm.validate()) {
				return false;
			}
			const attachments = map(this.project.attachment, (row) => {
				delete row["file"];
				return { ...row };
			});
			const bankers_guarantee_attachments = map(this.project.bankers_guarantee.attachment, (row) => {
				delete row["file"];
				return { ...row };
			});

			const formData = {
				contact: this.project.customer.id,
				contact_person: this.project.customer.contact_person_id,
				property: this.project.customer.property_id,
				start_date: this.project.start_date,
				end_date: this.project.end_date,
				purchase_order: this.project.purchase_order,
				name: this.project.title,
				quotations: this.project.quotations,
				reference_no: this.project.references_no,
				members: this.project.team_members,
				specifications: this.project.specification,
				description: this.project.description,
				project_value: String(this.project.value),
				project_cost: this.project.budget,
				files: attachments,
				bankers_guarantee: {
					bank: this.project.bankers_guarantee.bank,
					amount: this.project.bankers_guarantee.amount,
					remark: this.project.bankers_guarantee.remark,
					attachment: bankers_guarantee_attachments,
				},
			};
			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					const { uuid } = await UpdateProject(_this.uuid, formData);

					_this.$router.replace({
						name: "project-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Project has been updated." },
					]);
				} else {
					const { uuid } = await CreateProject(formData);

					_this.$router.replace({
						name: "project-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Project has been created." },
					]);
				}
				// clearing local storage data
				deleteData("create_project");
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async getProject() {
			try {
				GetProject(this.uuid)
					.then((data) => {
						this.barcode = data[0].barcode;
						this.project.title = data[0].name;
						this.project.id = data[0].id;
						this.customer = {
							id: data[0].contact ? data[0].contact : null,
							property_id: data[0].property ? data[0].property : null,
							contact_person_id: data[0].contact_person ? data[0].contact_person : null,
						};
						this.selectedCustomer = {
							id: data[0]?.contact,
							property_id: data[0]?.property,
							contact_person_id: data[0]?.contact_person,
						};
						this.project.start_date = data[0]?.start_date;
						// this.project.references_no = data[0]?.reference_no;
						this.project.references_no = data[0]?.reference_no ? data[0]?.reference_no : null;
						this.project.end_date = data[0]?.end_date;
						this.project.description = data[0]?.description ? data[0]?.description : null;
						const purchase_order =
							data[0].purchase_orders &&
							data[0].purchase_orders.map((purchase_order) => {
								return purchase_order.id;
							});
						const quotations =
							data[0].quotations &&
							data[0].quotations.map((quotation) => {
								return quotation.id;
							});

						this.project.purchase_order = purchase_order;
						this.project.quotations = quotations;
						this.project.value = data[0].project_value ? data[0]?.project_value : null;
						this.project.specification = data[0]?.specifications;
						this.project.bankers_guarantee.bank = data[0]?.bank;
						this.project.bankers_guarantee.amount = data[0]?.bankers_guarantee_amount;
						this.project.bankers_guarantee.remark = data[0]?.bankers_guarantee_remark
							? data[0].bankers_guarantee_remark
							: null;
						this.project.budget = data[0]?.cost ? data[0]?.cost : null;
						this.project.name = data[0]?.name;
						if (data[0].team_members && data[0]?.team_members.length > 0) {
							this.project.team_members = data[0].team_members.map((ele) => {
								return ele.id;
							});
						}

						if (this.project.contact > 0) {
							this.getCustomerProperty(this.project.contact);
						}

						this.$store.dispatch(SET_BREADCRUMB, [
							{ text: "Project", disabled: true },
							{ text: "Update", disabled: true },
							{ text: this.barcode, disabled: true },
						]);
						// get quotation and purchase order for dropdown list by project id
						this.getQuotationAndPO(this.project.id);
					})
					.catch((error) => {
						this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					})
					.finally(() => {
						this.pageLoading = false;
					});
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
		pageTitle() {
			if (this.uuid) {
				return this.project.name;
			}
			return "Create new Project";
		},

		remove(item) {
			this.project.team_members = item;
			this.saveToLocal();
		},

		async getQuotationAndPO(id) {
			try {
				const data = await getQuotationAndPurchaseOrder(id);
				this.purchaseOrderItem = [...data.purchase_orders];
				this.quoteItems = [...data.quotations];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		saveToLocal() {
			if (this.$route.name === "project-create") {
				const create_project = {
					customer: this.project.customer,
					start_date: this.project.start_date,
					end_date: this.project.end_date,
					title: this.project.title,
					quotations: this.project.quotations,
					references_no: this.project.references_no,
					specification: this.project.specification,
					team_members: this.project.team_members,
					description: this.project.description,
					value: this.project.value,
					budget: this.project.budget,
					bank: this.project.bankers_guarantee.bank,
					bankers_guarantee_amount: this.project.bankers_guarantee.amount,
					bankers_guarantee_remark: this.project.bankers_guarantee.remark,
				};
				saveData("create_project", create_project);
			}
		},
		savePOToLocal() {
			saveData("create_project_purchase_order", this.project.purchase_order);
		},
		saveQuotationToLocal() {
			saveData("create_project_quotation", this.project.quotations);
		},
		changeCustomerId() {
			this.saveToLocal();
		},
		loadLocalData() {
			let localSaveProject = getData("create_project");
			if (localSaveProject) {
				this.project.customer = localSaveProject.customer;
				this.selectedCustomer.id = localSaveProject.id;
				this.selectedCustomer.contact_person_id = localSaveProject.contact_person_id;
				this.selectedCustomer.property_id = localSaveProject.property_id;
				this.project.start_date = localSaveProject.start_date;
				this.project.end_date = localSaveProject.end_date;
				this.project.purchase_order = localSaveProject.purchase_order;
				this.project.quotation_number = localSaveProject.quotation_number;
				this.project.description = localSaveProject.description;
				this.project.title = localSaveProject.title;
				this.project.quotations = localSaveProject.quotations;
				this.project.references_no = localSaveProject.references_no;
				this.project.specification = localSaveProject.specification;
				this.project.team_members = localSaveProject.team_members;
				this.project.description = localSaveProject.description;
				this.project.value = localSaveProject.value;
				this.project.bank = localSaveProject.bank;
				this.project.budget = localSaveProject.budget;
				this.project.bankers_guarantee.amount = localSaveProject.bankers_guarantee_amount;
				this.project.bankers_guarantee.remark = localSaveProject.bankers_guarantee_remark;
			}
		},
		closeCreateDialog() {
			this.createDialog = false;
			deleteData("create_project");
			deleteData("create_project_purchase_order");
			deleteData("create_project_quotation");
		},
	},
	async mounted() {
		// this.pageLoading=true
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Project", disabled: true },
			{ text: "Create", disabled: true },
		]);
		this.$store.dispatch(SET_LOCAL_DB).then(() => {
			this.customerList = this.localDB("customers", []);
			this.teamMembersList = this.localDB("members", []);
			this.bankList = this.localDB("banks", []);
		});

		const { name, params } = this.$route;
		if (name === "project-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			try {
				await this.getProject();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		} else {
			this.loadLocalData();
			await this.getQuotationAndPO(0);
		}
		this.pageLoading = false;
	},
	computed: {
		// ...mapGetters(["errors"]),
		...mapGetters(["errors", "localDB"]),
	},

	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
<style scoped>
.v-text-field__details {
	display: none !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
	display: none;
}
</style>
